
import { Component, Prop, Vue } from 'vue-property-decorator';
import GPeoplePicker from '@/components/gsk-components/GskPeoplePicker.vue';
import DataTable from '@/components/table/DataTable.vue';
import { TableDefinition } from '@/components/table/table.types';
import GButton from '@/components/gsk-components/GskButton.vue';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import GCheckbox from '@/components/gsk-components/GskCheckbox.vue';
import { randInt } from '@/utils/components';
import { LogicalBotCategoryFormFieldData } from '@/features/om27/types/edit-bot.types';

interface TableData {
  label: string;
  action: LogicalBotCategoryFormFieldData;
}

@Component({
  components: {
    GButton,
    GTextfield,
    GCheckbox,
    GPeoplePicker,
    DataTable,
  },
})
export default class CategoryInput extends Vue {
  @Prop(Array) readonly value!: LogicalBotCategoryFormFieldData[];
  @Prop({ type: Boolean, default: true }) readonly valid!: boolean;

  category = '';
  subcategory = '';
  subsubcategory = '';
  isNewCategory = false;

  validationFlags = {
    isCategoryValid: true,
    isSubCategoryValid: true,
    isSubSubCategoryValid: true,
  };

  onCategoryInput() {
    this.validationFlags.isCategoryValid = true;
  }

  onSubCategoryInput() {
    this.validationFlags.isSubCategoryValid = true;
  }

  onSubSubCategoryInput() {
    this.validationFlags.isSubSubCategoryValid = true;
  }

  newCat() {
    return {
      // negative to avoid clashing with real ids
      category: this.category,
      subcategory: this.subcategory,
      subsubcategory: this.subsubcategory,
      isNewCategory: this.isNewCategory,
      id: randInt() * -1,
    };
  }

  addCat() {
    if (!this.category) {
      this.validationFlags.isCategoryValid = false;
    }

    if (!this.subcategory) {
      this.validationFlags.isSubCategoryValid = false;
    }

    if (!this.subsubcategory) {
      this.validationFlags.isSubSubCategoryValid = false;
    }

    if (this.category && this.subcategory && this.subsubcategory) {
      const exist = this.value.some(
        c =>
          c.category?.trim() === this.category?.trim() &&
          c.subcategory?.trim() === this.subcategory?.trim() &&
          c.subsubcategory?.trim() === this.subsubcategory?.trim(),
      );
      if (!exist) {
        this.$emit('input', [...this.value, this.newCat()]);
      }
      this.category = '';
      this.subcategory = '';
      this.subsubcategory = '';
      this.isNewCategory = false;
    }
  }

  removeCat(id: number) {
    this.$emit(
      'input',
      this.value.filter(cat => cat.id !== id),
    );
  }

  get tableDef(): TableDefinition<TableData> {
    return {
      data: this.value.map(cat => ({
        label: `${cat.category} - ${cat.subcategory} - ${cat.subsubcategory}`,
        action: cat,
      })),
      columns: [
        {
          key: 'label',
          label: 'Category / Sub-Category / Sub-Sub-Category',
        },
        {
          key: 'action',
          label: '',
        },
      ],
      rowKey: 'label',
    };
  }

  get eventHandlers() {
    return {
      change: (e: Event) => this.$emit('change', e),
      blur: (e: Event) => this.$emit('blur', e),
    };
  }
}
