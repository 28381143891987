
import { Component, Prop, Vue } from 'vue-property-decorator';
import { randId } from '@/utils/components';

const required = true;
@Component({
  inheritAttrs: false,
})
export default class GCheckbox extends Vue {
  @Prop({ type: Boolean }) readonly value!: boolean;
  @Prop({ type: String, default: 'cb' }) readonly name!: string;
  @Prop({ type: String, required }) readonly label!: string;

  get randName() {
    return `${this.name}_${randId()}`;
  }

  get eventHandlers() {
    return {
      change: this.input,
      blur: (e: Event) => this.$emit('blur', e),
    };
  }

  input(e: Event) {
    this.$emit('input', (e.target as HTMLInputElement)?.checked ?? false);
  }
}
