
import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import { TextField } from '@gsk-tech/gsk-textfield/gsk-textfield';

@Component({
  inheritAttrs: false,
})
export default class GTextfield extends Vue {
  @Prop({ required: false, type: [String, Number] }) readonly value!: string | number;
  @Prop(String) readonly helperTextContent!: string | undefined;
  @Prop({ type: String, default: '' }) readonly label!: string;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly validated!: boolean;

  get computedClasses() {
    return {
      'hide-helper-text': typeof this.helperTextContent === 'undefined' && !this.$attrs.maxlength,
      tf: true,
    };
  }

  get eventHandlers() {
    return {
      ...this.$listeners,
      input: this.input,
      blur: (e: Event) => this.$emit('blur', e),
      change: (e: Event) => this.$emit('change', e),
    };
  }

  @Emit()
  input(e: Event): string {
    if (e.target !== null) {
      return (e.target as TextField).value;
    }

    return '';
  }
}
